<template>
  <div class="wameed-dashboard-page-content">


    <page-header
        :title="$t('users.driver.title')"
        :btn-title="$t('users.driver.new_btn')"
        :route-name="'users-drivers-create'"
    >
      <template v-slot:btns>
        <div class="outline-link" @click="exportDriversToExcel">
          <o-export-icon/>
          {{ $t('common.export_excel') }}
        </div>
      </template>
    </page-header>


    <div>
      <filter-header
          :content="filterContent"
          @applyFilter="applyFilter"
          @resetFilter="resetFilter"
          @orderApplicants="orderData"

          :searchPlaceholder="$t('common.search') + ' ...'"
          :btnTitle="$t('common.filter_data')"
          :menuTitle="$t('common.order_by') + ' : '"
          :subTitle="'asdf'"
          :orderByItems=" [
        {
          name: this.$i18n.t('common.newest'),
          id: 'desc',
        },
        {
          name: this.$i18n.t('common.older'),
          id: 'asc',
        },
        {
          name: this.$i18n.t('common.alphabetical'),
          id: 'name',
        },
      ]"
      />
    </div>
    <section
        class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      ">
      <div class="d-flex flex-wrap flex-1 ">
        <b-col lg="12" md="12" class="px-0">

          <div class="w-table">
            <w-tables
                :noDataTitle="$t('table.no_data_title')"
                :noDataSubtitle="$t('table.no_data_subtitle')"
                v-if="getData"
                :per-page="filterData.per_page"
                :items="getData"
                :fields="tableFields"
                :custimized-items="[
                { name: 'created_at' },
                { name: 'status' },
                { name: 'status' },
                { name: 'action' },
              ]"
            >


              <template slot="status" slot-scope="{ data }">
                <general-status-badge
                    :status="data.item.status"/>
              </template>

              <template slot="status" slot-scope="{ data }">
                <wameed-switch
                    :checked="data.item.status ===1"
                    @onChange="(e)=>{e.preventDefault();  onChange(data.item)}"/>
              </template>

              <template slot="created_at" slot-scope="{ data }">
                {{ formatDate(data.item.created_at, 'YYYY-MM-DD') }}
              </template>

              <template slot="action" slot-scope="{ data }">
                <b-dropdown
                    variant="background"
                    class="table-option"
                    toggle-class="text-decoration-none rounded-10 px-2 py-2 "
                    menu-class="rounded-8"
                    no-caret
                    dropleft
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon/>
                  </template>

                  <b-dropdown-item
                      class="permission-edit-item"
                      v-permission="[$route.meta.pagePermissions.edit]"
                      :to="goToUpdate(data.item.id)"
                  >
                    <span class="text-regular-14 text-font-secondary">

                      <edit-icon class="mx-2"/>
                      {{ $t('btn.edit') }}
                    </span>
                  </b-dropdown-item>


                  <b-dropdown-item
                      :to="goToDetail(data.item.id)"
                  >
                    <span class="text-regular-14 text-font-secondary permission-show-detail">
                      <eyeon-icon class="mx-2"/>
                      {{ $t('common.show_details') }}
                    </span>
                  </b-dropdown-item>


                </b-dropdown>
              </template>
            </w-tables>
          </div>

        </b-col>

        <b-col lg="12" md="12" class="px-0">
          <wameed-pagination
              v-if="getTotal"
              v-model="filterData.page"
              :page="filterData.page"
              :total-items="getTotal.totalItems"
              :per_page="filterData.per_page"
              :menu-title="$t('common.show_results')"
              @changePage="changePage"
              @changeCurrentPage="changeCurrentPage"
          />
        </b-col>

      </div>

    </section>

    <warning-modal
        variant="main"
        iconName="o-toggle-icon"
        :visible="showModal"
        @close="showModal = false"
        @submitAction="toggleAction"
        :title="modal.title"
        :subTitle="modal.subTitle"
        :btnTitle="modal.btn"
    />

  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import FilterHeader from '@/components/wameed/WameedFilterHeader.vue';
import {WameedPagination, WameedSwitch, WameedTables as WTables} from 'wameed-ui/dist/wameed-ui.esm'
import WarningModal from "@/components/WarningModal";
import CheckValue from "@/components/checkValue";
import CheckPrice from "@/components/checkPrice";
import NameTile from "@/components/nameTile.table";
import GeneralStatusBadge from "@/components/generalStatusBadge";
import WameedWarningModal from "@/components/wameed/WameedWarningModal";

;

export default {
  components: {
    WameedWarningModal,
    GeneralStatusBadge,
    NameTile,
    CheckPrice,
    CheckValue,
    WarningModal,
    PageHeader,
    FilterHeader,
    WameedPagination,
    WameedSwitch,
    WTables,
  },
  data() {
    return {
      showModal: false,
      modal: {
        title: this.$i18n.t('users.inactive.title'),
        subTitle: this.$i18n.t('users.inactive.sub_title'),
        btn: this.$i18n.t('users.inactive.btn'),
      },
      toggleUser: {id: 0, status: 0},
      currentItemData: null,
      filterData: {
        status: '',
        page: 1,
        per_page: 20,
        order_by: 'desc',
        search: '',
      },
      filterContent: [
        {
          title: this.$i18n.t('common.status'),
          key: 'status',
          id: 'id',
          name: 'title',
          type: 'text',
          selectedData: [],
          translate: true,
          data: [
            {
              id: 'available',
              title: this.$i18n.t('status.available'),
            },
            {
              id: 'not_available',
              title: this.$i18n.t('status.not_available'),
            },
          ],
        },
      ],

      tableFields: [
        {
          key: "index",
          label: "#",
          sortable: true,
        },

        {
          key: 'name',
          label: this.$i18n.t('table.name'),
          sortable: false,
          tdClass: 'w-2/8'
        },

        {
          key: 'delivery_type',
          label: this.$i18n.t('users.table.delivery_type'),
          sortable: false,
          tdClass: 'w-1/8'
        },

        {
          key: 'phone',
          label: this.$i18n.t('users.table.phone'),
          sortable: false,
          tdClass: 'w-1/8'
        },

        {
          key: 'created_at',
          label: this.$i18n.t('users.table.registered_at'),
          sortable: false,
          tdClass: 'w-1/8'
        },

        {
          key: 'status',
          label: this.$i18n.t('table.availability'),
          sortable: false,
          tdClass: 'w-1/8'
        },
        {
          key: 'status',
          label: this.$i18n.t('common.status'),
          sortable: false,
          tdClass: 'w-1/8'
        },
        {
          key: 'action',
          label: this.$i18n.t('table.options'),
          tdClass: 'w-1/8'
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getVehicleTypes: 'admin/users/getVehicleTypes',

      getData: 'admin/users/getData',
      getTotal: 'admin/users/getTotal',
    }),
  },
  async created() {
    await this.$store.dispatch('admin/users/unsetData');
    await this.$store.dispatch('admin/users/getVehicleTypes');
    this.filterContent.push(
        {
          title: this.$i18n.t('filters.vehicle_types'),
          key: 'vehicle_types',
          id: 'id',
          type: 'text',
          name: 'title',
          selectedData: [],
          translate: true,
          data: this.getVehicleTypes.map((item) => {
            return {
              id: item.id,
              title: item.name
            }
          }),
        },
    )
    this.loadData();
  },
  methods: {
    exportDriversToExcel() {
      this.$store.dispatch('admin/users/exportExcel', {type: 'drivers'})
    },

    onChange(item) {
      let status = 1;
      if (item.status === 1) {
        this.modal = {
          title: this.$i18n.t('users.inactive.title'),
          subTitle: this.$i18n.t('users.inactive.sub_title'),
          btn: this.$i18n.t('users.inactive.btn'),
        }
        status = 0;
      } else {
        this.modal = {
          title: this.$i18n.t('users.active.title'),
          subTitle: this.$i18n.t('users.active.sub_title'),
          btn: this.$i18n.t('users.active.btn'),
        }

      }
      this.toggleUser = {id: item.id, status: status};
      this.showModal = true;
    },

    toggleAction() {
      this.$store.dispatch('admin/users/toggle', this.toggleUser)
      this.showModal = false;
    },
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
        page:1,
      };
      this.loadData();
    },

    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },

    loadData() {
      this.$store.dispatch('admin/users/get', {...this.filterData, type: 'drivers'})
    },


    goToUpdate(id) {
      return {
        name: 'users-drivers-update',
        params: {lang: this.$i18n.locale, id},
      };
    },


    goToDetail(id) {
      return {
        name: 'users-drivers-detail',
        params: {lang: this.$i18n.locale, id},
      };
    },

    applyFilter() {
      this.filterContent.forEach(item => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },


    resetFilter() {
      this.filterContent.forEach((item, i) => {
        this.filterContent[i].selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },


    orderData(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },
  },
};
</script>
